@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueBlack.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueBlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueBoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueHeavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueHeavyItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueMediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueRoman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueThin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueUltraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueUltraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

.copy-block-container span {
  background-color: #DDF0F4 !important; 
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F6F4F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c1be;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width:641px) {

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
}

.custom-scrollbar-hide {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.custom-scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}